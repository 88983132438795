import React, { useContext, useState, useEffect } from "react";
import GlobalContext from "../context/GlobalContext";
import { getDiaSemana, getMes } from "../util";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";
import dayjs from "dayjs";
import queryString from "query-string";
import Select from "react-select";
registerLocale("pt-BR", ptBR);

const labelsClasses = [
  "indigo",
  "gray",
  "green",
  "blue",
  "red",
  "purple",
];

export default function EventModal() {
  const {
    setShowEventModal,
    daySelected,
    dispatchCalEvent,
    selectedEvent,
  } = useContext(GlobalContext);

  const [idApp, setIdApp] = useState(
    selectedEvent ? selectedEvent._id : ""
  );
  const [title, setTitle] = useState(
    selectedEvent ? selectedEvent.title : ""
  );
  const [description, setDescription] = useState(
    selectedEvent ? selectedEvent.description : ""
  );
  const [selectedLabel, setSelectedLabel] = useState(
    selectedEvent
      ? labelsClasses.find((lbl) => lbl === selectedEvent.label)
      : labelsClasses[0]
  );

  const [horaInicio, setHoraInicio] = useState(
    selectedEvent ? selectedEvent.horaInicio : ""
  );
  const [horaFim, setHoraFim] = useState(
    selectedEvent ? selectedEvent.horaFim : ""
  );

  const [lista, setLista] = useState([]);

  const [listaMedicos, setListaMedicos] = useState([]);

  const [idTipoCompromisso, setIdTipoCompromisso] = useState(
    selectedEvent ? selectedEvent.tipoCompromisso._id : ""
  );

  const [tipoCompromissoSelecionado, setTipoCompromissoSelecionado] =
    useState(selectedEvent ? selectedEvent.tipoCompromisso : null);

  const [dataInicial, setDataInicial] = useState(
    dayjs(daySelected).toDate()
  );
  const [dataFinal, setDataFinal] = useState(
    dayjs(daySelected).toDate()
  );

  const [codigo, setCodigo] = useState(
    queryString.parse(window.location.search).codigo
  );

  const [medicosSelecionados, setMedicosSelecionados] = useState([]);

  const [bloquearEdicaoHorario, setBloquearEdicaoHorario] = useState(false);

  function notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (idTipoCompromisso === "") {
      notificar("warning", "Selecione o tipo do evento!");
      return false;
    }

    if (tipoCompromissoSelecionado !== null) {
      if (!tipoCompromissoSelecionado.diaInteiro) {
        if (horaInicio === "") {
          notificar("warning", "Favor informar hora de início");
          return false;
        }
        if (horaFim === "") {
          notificar("warning", "Favor informar hora de fim");
          return false;
        }
      }
    }

    let medicos = medicosSelecionados;

    for (let medico of medicos) {
      let calendarEvent = {
        title,
        description,
        //label: selectedLabel,
        label: tipoCompromissoSelecionado.label,
        day: daySelected.valueOf(),
        id: selectedEvent ? selectedEvent.id : Date.now(),
        horaInicio,
        horaFim,
        tipoCompromisso: tipoCompromissoSelecionado,
        data: daySelected.format("YYYY-MM-DD") + "T03:00:00.000Z",
        idUsuario: "1",
        medico: medico.codigo,
        dataInicial:
          daySelected.format("YYYY-MM-DD") + "T03:00:00.000Z",
        dataFinal:
          dayjs(dataFinal).format("YYYY-MM-DD") + "T03:00:00.000Z",
        sigla: medico.sigla2 !== undefined ? (medico.sigla2 !== "" ? medico.sigla2 : null) : null
      };

      if (idApp !== "") {
        calendarEvent._id = idApp;
      }

      if (selectedEvent) {
        await axios
          .post(
            "https://estsistemas.com.br:3002/app/agenda",
            { eventoNovo: calendarEvent },
            {}
          )
          .then((response) => {
            if (response.data.valido) {
              notificar(
                "success",
                "Compromisso editado com sucesso!"
              );
              dispatchCalEvent({
                type: "update",
                payload: calendarEvent,
              });
            } else {
              notificar("warning", response.data.msg);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        await axios
          .post(
            "https://estsistemas.com.br:3002/app/agenda",
            { eventoNovo: calendarEvent },
            {}
          )
          .then((response) => {
            // console.clear();
            console.log(response.data);

            if (response.data.valido) {
              calendarEvent._id = response.data.idCompromisso;
              notificar(
                "success",
                "Compromisso marcado com sucesso!"
              );

              dispatchCalEvent({
                type: "push",
                payload: calendarEvent,
              });

              // if (calendarEvent.tipoCompromisso.tipo === 2) {
              //   setTimeout(() => {
              //     window.location.reload();
              //   }, 1000);
              // }
            } else {
              alert(response.data.msg);
              //  console.clear()
              //  console.log(notificar)
              // notificar("warning", response.data.msg);

              ///
              // toast.error("teste", {
              //   position: "top-right",
              //   autoClose: 3000,
              //   hideProgressBar: false,
              //   closeOnClick: true,
              //   pauseOnHover: true,
              //   draggable: true,
              // });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }

    setShowEventModal(false);
  }

  async function handleClickDelete() {
    await axios
      .post(
        "https://estsistemas.com.br:3002/app/agenda-deletar",//http://localhost:3333
        { idCompromisso: selectedEvent._id },
        {}
      )
      .then((response) => {
        notificar("success", "Compromisso deletado com sucesso!");

        dispatchCalEvent({
          type: "delete",
          payload: selectedEvent,
        });
        setShowEventModal(false);
        if (selectedEvent.tipoCompromisso.tipo === 2) {
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((error) => {
        console.log(error);
        notificar("warning", "Ocorreu um erro no servidor!");
      });
  }

  useEffect(() => {
    async function getTiposCompromisso() {
      await axios
        .get("https://estsistemas.com.br:3002/app/tipos-compromisso", {})
        .then((response) => {
          console.log(response);
          let tiposCompromisso = response.data.tiposCompromisso;
          tiposCompromisso = tiposCompromisso.map(
            (tipoCompromisso) => {
              tipoCompromisso.label = tipoCompromisso.desc;
              tipoCompromisso.value = tipoCompromisso._id;
              return tipoCompromisso;
            }
          );

          console.log("lalalal", tiposCompromisso);
          setLista(tiposCompromisso);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (lista.length === 0) {
      getTiposCompromisso();
    }
  }, []);

  useEffect(() => {
    async function getMedicos() {
      await axios
        .get("https://estsistemas.com.br:3002/app/medicos", {})
        .then((response) => {
          console.log("resp", response.data.medicos);
          let medicos = response.data.medicos;
          medicos = medicos.map((med) => {
            med.label = med.nome;
            med.value = med._id;
            return med;
          });


          let medicosSelecionados = undefined;

          if(selectedEvent){
          medicosSelecionados = medicos.find(
              (med) => med.codigo === selectedEvent.medico
            );
          }else{
          // medicosSelecionados = medicos.find(
          //     (med) => med.codigo === codigo
          //   );
          }

          
          if (medicosSelecionados !== undefined) {
            setMedicosSelecionados([{...medicosSelecionados}]);
          }

          setListaMedicos(medicos);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (listaMedicos.length === 0) {
      getMedicos();
    }
  }, []);

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      <div className="h-screen w-full fixed left-0 top-0 flex justify-center items-center">
        <form
          className="bg-white rounded-lg shadow-2xl w-4/4"
          style={{ minWidth: "430px", maxWidth: "430px" }}
        >
          <header className="bg-gray-100 px-4 py-2 flex justify-between items-center">
            <span className="material-icons-outlined text-gray-400">
              drag_handle
            </span>
            <div>
              {selectedEvent &&
                selectedEvent.tipoCompromisso.tipo !== 0 && (
                  <span
                    onClick={handleClickDelete}
                    className="material-icons-outlined text-gray-400 cursor-pointer"
                  >
                    delete
                  </span>
                )}
              <button onClick={() => setShowEventModal(false)}>
                <span className="material-icons-outlined text-gray-400">
                  close
                </span>
              </button>
            </div>
          </header>

          <div className="p-3">
            <div className="grid grid-cols-1/6 items-end gap-y-7">
              <div></div>
              {/* <div>
              <select className="pt-3 border-0 text-gray-600 pb-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-500">
                <option>teste</option>
              </select>
            </div> */}

              {!selectedEvent && (
                <Select
                  placeholder="Selecione o Compromisso"
                  options={lista}
                  onChange={(tipoCompromisso) => {
                    if(tipoCompromisso.horaInicio !== undefined){
                      setHoraInicio(tipoCompromisso.horaInicio)
                      setHoraFim(tipoCompromisso.horaFim)
                      setBloquearEdicaoHorario(true)
                    }else{
                      setBloquearEdicaoHorario(false)
                    }
                    setIdTipoCompromisso(tipoCompromisso.value);
                    setTipoCompromissoSelecionado(tipoCompromisso);
                  }}
                  value={tipoCompromissoSelecionado}
                />

                // <select
                //   type="text"
                //   name="title"
                //   placeholder="Add title"
                //   value={idTipoCompromisso}
                //   required
                //   className="pt-3 border-0 text-gray-600 text-xl font-semibold pb-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-500"
                //   onChange={(e) => {
                //     if (e.target.value === "null") {
                //       return false;
                //     }
                //     setIdTipoCompromisso(e.target.value);
                //     setTipoCompromissoSelecionado(
                //       lista.find((it) => it._id === e.target.value)
                //     );
                //   }}
                // >
                //   <option value={"null"}>
                //     Selecione o compromisso
                //   </option>

                //   {lista.map((item) => (
                //     <option value={item._id}>{item.desc}</option>
                //   ))}
                // </select>
              )}
              {!selectedEvent && (
                <Select
                  options={listaMedicos}
                  //options={this.state.anestesistas1}
                  //value={this.state.anestesistaEditar}
                  //onChange={(item) => this.onChangeAnestesistaEditar(item)}
                  placeholder="Selecione o Usuário"
                  isMulti={true}
                  value={medicosSelecionados}
                  onChange={(medicosSelecionados) =>
                    setMedicosSelecionados(medicosSelecionados)
                  }
                />

                // <select
                //   type="text"
                //   name="title"
                //   placeholder="Add title"
                //   value={idTipoCompromisso}
                //   required
                //   className="pt-3 border-0 text-gray-600 text-xl font-semibold pb-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-500"
                //   // onChange={(e) => {
                //   //   if (e.target.value === "null") {
                //   //     return false;
                //   //   }
                //   //   setIdTipoCompromisso(e.target.value);
                //   //   setTipoCompromissoSelecionado(
                //   //     lista.find((it) => it._id === e.target.value)
                //   //   );
                //   // }}
                // >
                //   <option value={"null"}>Selecion2e</option>

                //   {listaMedicos.map((item) => (
                //     <option value={item._id}>{item.nome}</option>
                //   ))}
                // </select>
              )}
              {selectedEvent &&
                selectedEvent.tipoCompromisso.tipo === 1 && (
                  <select
                    type="text"
                    name="title"
                    placeholder="Add title"
                    value={idTipoCompromisso}
                    required
                    className="pt-3 border-0 text-gray-600 text-xl font-semibold pb-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-500"
                    onChange={(e) => {
                      if (e.target.value === "null") {
                        return false;
                      }
                      setIdTipoCompromisso(e.target.value);
                      setTipoCompromissoSelecionado(
                        lista.find((it) => it._id === e.target.value)
                      );
                    }}
                    disabled={true}
                  >
                    <option value={"null"}>Selecione</option>

                    {lista.map((item) => (
                      <option value={item._id}>{item.desc}</option>
                    ))}
                  </select>
                )}
              {selectedEvent &&
                selectedEvent.tipoCompromisso.tipo !== 1 && (
                  <input
                    type="text"
                    value={selectedEvent.tipoCompromisso.desc}
                    className="pt-3 border-0 text-gray-600 pb-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-500"
                    disabled={true}
                  ></input>
                )}

                {/* {selectedEvent && (<span>{JSON.stringify(selectedEvent)}</span>)} */}

{selectedEvent && (<Select
                  options={listaMedicos}
                  //options={this.state.anestesistas1}
                  //value={this.state.anestesistaEditar}
                  //onChange={(item) => this.onChangeAnestesistaEditar(item)}
                  placeholder="Selecione o Usuário"
                  isMulti={true}
                  value={medicosSelecionados}
                  onChange={(medicosSelecionados) =>
                    setMedicosSelecionados(medicosSelecionados)
                  }
                  isDisabled={true}
                />
                )}


              <div style={{ display: "flex", flexDirection: "row" }}>
                <span className="material-icons-outlined text-gray-400">
                  schedule
                </span>
                <p>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {getDiaSemana(daySelected.format("d"))},&nbsp;
                  {daySelected.format("DD")} de&nbsp;
                  {getMes(daySelected.format("MM"), true)}&nbsp;
                </p>
              </div>

              {tipoCompromissoSelecionado !== null &&
                tipoCompromissoSelecionado.diaInteiro === false && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <span className="material-icons-outlined text-gray-400">
                      schedule
                    </span>
                    &nbsp;&nbsp;
                    <div style={{ display: "flex", width: "90%" }}>
                      <div
                        style={{ width: "45%", marginRight: "5%" }}
                      >
                        <p>
                          <input
                            type="time"
                            name="horaInicio"
                            // placeholder="Descrição"
                            value={horaInicio}
                            required
                            className="pt-3 border-0 text-gray-600 pb-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-500"
                            onChange={(e) =>
                              setHoraInicio(e.target.value)
                            }
                            disabled={bloquearEdicaoHorario}
                          />
                        </p>
                      </div>
                      <div style={{ width: "45%", marginLeft: "5%" }}>
                        <p>
                          <input
                            type="time"
                            name="horaFim"
                            //placeholder="Descrição"
                            value={horaFim}
                            required
                            className="pt-3 border-0 text-gray-600 pb-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-500"
                            onChange={(e) =>
                              setHoraFim(e.target.value)
                            }
                            disabled={bloquearEdicaoHorario}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              {tipoCompromissoSelecionado !== null &&
                tipoCompromissoSelecionado.diaInteiro === true &&
                tipoCompromissoSelecionado.tipo === 2 &&
                !selectedEvent && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <span className="material-icons-outlined text-gray-400">
                      schedule
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <div style={{ display: "flex", width: "90%" }}>
                      <div
                        style={{ width: "45%", marginRight: "5%" }}
                      >
                        <p>
                          <DatePicker
                            selected={dataInicial}
                            customInput={
                              <input className="pt-3 border-0 text-gray-600 pb-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-500" />
                            }
                            disabled={true}
                            dateFormat="dd/MM/yyyy"
                            // onChange={(date) => {
                            //   this.setState(
                            //     { dataInicial: date },
                            //     () => this.load()
                            //   );
                            // }}
                            locale="pt-BR"
                          />
                        </p>
                      </div>
                      <div style={{ width: "45%", marginLeft: "5%" }}>
                        <p>
                          <DatePicker
                            selected={dataFinal}
                            customInput={
                              <input className="pt-3 border-0 text-gray-600 pb-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-500" />
                            }
                            disabled={false}
                            dateFormat="dd/MM/yyyy"
                            onChange={(date) => {
                              setDataFinal(date);
                            }}
                            locale="pt-BR"
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <span className="material-icons-outlined text-gray-400">
                  segment
                </span>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <input
                  type="text"
                  name="description"
                  placeholder="Descrição"
                  value={description}
                  required
                  className="pt-3 border-0 text-gray-600 pb-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-500"
                  onChange={(e) => setDescription(e.target.value)}
                  disabled={
                    selectedEvent !== null
                      ? selectedEvent.tipoCompromisso.tipo !== 1
                        ? true
                        : false
                      : false
                  }
                />
              </div>
              {/* <span className="material-icons-outlined text-gray-400">
              bookmark_border
            </span>
            <div className="flex gap-x-2">
              {labelsClasses.map((lblClass, i) => (
                <span
                  key={i}
                  onClick={() => setSelectedLabel(lblClass)}
                  className={`bg-${lblClass}-500 w-6 h-6 rounded-full flex items-center justify-center cursor-pointer`}
                >
                  {selectedLabel === lblClass && (
                    <span className="material-icons-outlined text-white text-sm">
                      check
                    </span>
                  )}
                </span>
              ))}
            </div> */}
            </div>
          </div>
          <footer className="flex justify-end border-t p-3 mt-5">
            {!selectedEvent && (
              <button
                type="submit"
                onClick={handleSubmit}
                className="bg-blue-500 hover:bg-blue-600 px-6 py-2 rounded text-white"
              >
                Salvar
              </button>
            )}

            {selectedEvent &&
              selectedEvent.tipoCompromisso.tipo === 1 && (
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="bg-blue-500 hover:bg-blue-600 px-6 py-2 rounded text-white"
                >
                  Editar
                </button>
              )}
          </footer>
        </form>
      </div>
    </React.Fragment>
  );
}
